import './Logos.scss';

import React from 'react';

import LogoArtOfPlay from '../images/logo-art-of-play.inline.svg';
import LogoLandscape from '../images/logo-landscape.inline.svg';

const Logos = () => {
  return (
    <div className="logos">
      <a className="logos__link" href="http://thisislandscape.com/" rel="noreferrer" target="_blank">
        <LogoLandscape />
      </a>
      <span className="logos__divider" />
      <a className="logos__link" href="https://www.artofplay.com/" rel="noreferrer" target="_blank">
        <LogoArtOfPlay />
      </a>
    </div>
  );
};

export default Logos;
