import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Logos from '../components/Logos';
import { pageMount } from '../state/page';

const LearnPage = ({ onMount = () => {} }) => {
  /* Effect handling page mount */
  useEffect(() => {
    onMount();
  }, []);

  return (
    <div>
      <div className="page">
        <div className="page__text">
          <span>
            Rule of Three is a fast-paced card game for visual thinkers. Pair it with a festive libation to pass the
            hours you no longer have to spend with the in-laws. Excessive celebration doesn’t award more points, but it
            does feel good. Shuffle the cards and prepare to collect matching triplets of shapes, colors and numbers.
            Quick thinking, logic, and skill win the game!
          </span>
          <Logos />
        </div>
      </div>
    </div>
  );
};

export default connect(null, (dispatch) => ({
  onMount: () => {
    dispatch(pageMount('learn'));
  },
}))(LearnPage);
